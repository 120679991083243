<template>
  <div style="height:100%;">
    <HomepageCliente v-if="ruolo == 'Cliente'"/>
    <HomepageMaster v-if="ruolo == 'Master' || ruolo == 'Partner'"/>
    <HomepageTecnico v-if="ruolo == 'Tecnico'"/>
  </div>
</template>

<script>
import { generalFunction } from '@/js/generalFunction.js';
const HomepageCliente = () => import('@/views/Cliente/Homepage');
const HomepageMaster = () => import('@/views/Master/Homepage');
const HomepageTecnico = () => import('@/views/Tecnico/Homepage');

export default {
  name: 'Homepage',
  components: {
    HomepageCliente,
    HomepageMaster,
    HomepageTecnico
  },
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: undefined
    }
  },
  async created() {
    this.ruolo = await generalFunction.getRuolo(this.token);
  },
}
</script>
